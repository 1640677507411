<template>
  <div class="auth-page">
    <div class="container-fluid p-0">
      <div class="row g-0 align-items-center">
        <div class="col-xxl-4 col-lg-4 col-md-6">
          <div class="row justify-content-center g-0">
            <div class="col-xl-9">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="auth-full-page-content rounded d-flex p-3 my-2">
                    <div class="w-100">
                      <div class="d-flex flex-column h-100">
                        <div class="mb-5 mb-md-5">
                          <div class="d-block auth-logo">
                            <img src="@/assets/img/logo-argusblack.png" alt="" height="50" class="auth-logo-dark me-start"/>
                            <img src="@/assets/img/logo-argusblack-blanco.png" alt="" height="50" class="auth-logo-light me-start">
                          </div>
                        </div>
                        <div class="auth-content my-auto">
                          <div class="text-center">
                            <h5 class="mb-0">¿Se te olvidó tu identidad?</h5>
                            <p class="text-muted mt-2">Ingresa aquí el correo electrónico con el que registraste tu cuenta.</p>
                          </div>
                          <div ref="formularioCambiarClave" class="mt-5 pt-3" role="form">
                            <div class="form-floating form-floating-custom mb-4">
                              <input
                                type="email"
                                class="form-control"
                                id="correo"
                                ref="correo"
                                placeholder="Ingrese correo electrónico"
                                required
                                v-model="correo"
                              />
                              <label for="identidad">Correo electrónico</label>
                              <div class="form-floating-icon">
                                <eva-icon name="email-outline"></eva-icon>
                              </div>
                            </div>
                            <!-- <div class="mb-3">
                              <center>
                                <vue-recaptcha
                                  ref="recaptcha"
                                  @verify="onVerify"
                                  @expired="onExpired"
                                  :sitekey="sitekey">
                                </vue-recaptcha>
                              </center>
                            </div> -->
                            <div class="mb-3">
                              <button
                                class="btn btn-primary w-100 waves-effect waves-light"
                                @click="solicitarIdentidades()"
                                type="submit"
                              >
                                Solicitar identidad
                              </button>
                            </div>
                          </div>

                          <div class="text-center mt-4 pt-2" ref="spinner" style="display: none">
                            <div class="spinner-border text-primary m-1" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>

                          <div class="mt-4 pt-3 text-center">
                            <small>
                              ¿Deseas recuperar tu cuenta?
                              <router-link :to="{ name: 'recuperarCuenta' }">
                                Da click aquí
                              </router-link> 
                            </small>
                            <br />
                            <small>
                              ¿Desear ir al Login?
                              <router-link :to="{ name: 'login' }">
                                Da click aquí
                              </router-link>
                            </small>
                          </div>

                          <div class="mt-4 pt-3 text-center">
                            <p class="text-muted mb-0">
                              <img src="../assets/img/logo-hecho-en-mexico.png" class="bx-pull-right" />
                              <small>
                                <b>ArgusBlack</b> es mantenido por
                                <a href="https://livaur.com" target="_blank">Livaur</a>
                                {{ anio }}
                              </small>
                              <br>
                              <small>Versión: <strong>{{ version }}</strong></small>
                            </p>
                          </div>

                          <br v-if="visibleModo">

                          <div class="rounded overflow-hidden text-center" v-if="visibleModo">
                            <div class="bg-warning bg-gradient p-1">
                              <h5 class="my-2 text-white">
                                {{ modoDes == 'development' ? 'Development' : '' }}
                                {{ modoDes == 'testing' ? 'Testing' : '' }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end auth full page content -->
        </div>
        <!-- end col -->
        <div class="col-xxl-8 col-lg-8 col-md-6 d-none d-sm-block">
          <div class="auth-bg bg-white py-md-5 p-4 d-flex">
            <div class="bg-overlay bg-white"></div>
            <!-- end bubble effect -->
            <div class="row justify-content-center align-items-center">
              <div class="col-xl-8">
                <div class="mt-4">
                  <img src="@/assets/img/login-img.png" class="img-fluid" alt="">
                </div>
                <div class="p-0 p-sm-4 px-xl-0 py-5">
                  <div id="reviewcarouselIndicators" class="carousel slide auth-carousel" data-bs-ride="carousel">
                    <div class="carousel-indicators carousel-indicators-rounded">
                      <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <!-- end carouselIndicators -->
                    <div class="carousel-inner w-75 mx-auto">
                      <div class="carousel-item active">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Usa múltiples servicios</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            Puedes usar múltiples líneas de internet en el mismo router 
                            y definir qué clientes saldrán por alguno de esos servicios de internet.
                          </p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Sistema Cloud</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            No necesitas tener un servidor dedicado, el sistema trabaja y
                            administra tu red sin importar en qué parte del mundo te encuentres.
                          </p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Administración automática</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            Encargate de instalar más servicios de internet a tus clientes,
                            ArgusBlack se encargará de la parte administrativa, facturación,
                            cortes automáticos, control de ancho de banda, administración de
                            reuso de internet, etc.
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- end carousel-inner -->
                  </div>
                  <!-- end review carousel -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container fluid -->
  </div>
</template>

<script>
import SistemaSrv from '@/services/SistemaSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
// import { VueRecaptcha } from 'vue-recaptcha'
import { version } from '../../package'

export default {
  components: { /*VueRecaptcha*/ },
  name: 'RecuperarIdentidades',

  data() {
    return {
      correo: '',
      // sitekey:'6Lfb8s0ZAAAAAH9R0-A2adUbihSml2d6oS-uTKUW',
      // token_recaptcha: null,
      visibleModo: false,
      version: version,
      modoDes: ''
    };
  },
  computed: {
    anio: {
      get() {
        return this.$store.state.todo.anio
      }
    },
    googleClaveRecaptcha: function() {
      return this.$store.state.todo.google_clave_recaptcha
    }
  },
  created: function() {
    var self = this

    // Actualización del fondo de la aplicación cuando está en el login
    // document.body.removeAttribute("data-sidebar");

    // integracion de recaptcha enterprice
    var script = document.createElement('script')
    script.src = "https://www.google.com/recaptcha/enterprise.js?render=" + self.googleClaveRecaptcha
    // script.addEventListener('load', self.setLoadedPaypal)
    document.head.appendChild(script)

    self.modoDesarrollo()
  },
  mounted() {
    if(localStorage.getItem('argusblack.theme.mode') == 'light') {
      document.body.removeAttribute("data-sidebar")
      document.body.removeAttribute("data-layout-mode")
    } else {
      document.body.setAttribute("data-sidebar", "dark")
      document.body.setAttribute("data-layout-mode", "dark")
    }
  },
  methods: {
    // onVerify: function (response) {
    //   var self = this
    //   self.token_recaptcha = response
    // },

    // onExpired: function () {
    //   iu.msg.error('Expiro el tiempo recarge la vista a resete el Recaptcha')
    //   console.log('Expired')
    // },

    modoDesarrollo() {
      var self = this
      
      SistemaSrv.modo().then(response => {
        self.modoDes = response.data
        if(self.modoDes != 'production') self.visibleModo = true
        else self.visibleModo = false
      })
    },

    solicitarIdentidades: function() {
      var self = this, token = null

      self.$refs.formularioCambiarClave.hidden = true
      self.$refs.spinner.style.display = 'block'


      grecaptcha.enterprise.ready(async () => {
        token = await grecaptcha.enterprise.execute(self.googleClaveRecaptcha, { action: 'password_reset' })

        var params = {
          token_recaptcha: token
        }

        self.verificarCorreo(function() {
          self.verificarExistenciaCorreo(function() {
            UsuarioSrv.dmzSolicitarIdentidades(self.correo, params).then(response => {
              iu.msg.success('Se enviaron al correo electrónico las identidades asignadas')
              self.$router.push({ name: 'recuperarCuenta' })
              
              self.$refs.spinner.style.display = 'none'
            }).catch(error => {
              let mensaje
              try {
                mensaje = error.response.data.message
              } catch (error) {
                mensaje = "No se pudo obtener las identidades"
              }
              iu.msg.error(mensaje)
              console.log(error)
              
              self.$refs.formularioCambiarClave.hidden = false
              self.$refs.spinner.style.display = 'none'
            })
          }, () => {
            self.$refs.formularioCambiarClave.hidden = false
            self.$refs.spinner.style.display = 'none'
          })
        })
      })
    },

    verificarCorreo: function(callback) {
      var self = this

      if ( self.correo == '' || self.correo.indexOf('@') == -1 || self.correo.indexOf('.') == -1 ) {
        iu.msg.warning('Es necesario un correo electrónico válido')
        self.$refs.correo.select()
        self.$refs.formularioCambiarClave.hidden = false
        self.$refs.spinner.style.display = 'none'
        return
      }

      // Si la verificación fue correcta, manda a llamar el callback
      callback()
    },

    verificarExistenciaCorreo: function(cbSuc, cbErr) {
      var self = this

      // Verifica que el correo exista
      var existeCorreo = false
      UsuarioSrv.dmzExisteCorreo(self.correo).then(response => {
        existeCorreo = response.data
        if (existeCorreo == false) {
          iu.msg.warning('El correo no está registrado')
          self.$refs.formularioCambiarClave.hidden = false
          self.$refs.spinner.style.display = 'none'
          setTimeout(function() {
            self.$refs.correo.select()
          }, 500)
        }

        // Si el correo está registrado manda a llamar el callback
        cbSuc()
      }).catch(error => {
        iu.msg.error('No se pudo verificar si existe el correo, intentalo más tarde')
        console.log(error)

        self.$refs.formularioCambiarClave.hidden = false
        self.$refs.spinner.style.display = 'none'
        cbErr()
      })
    }
  }
}
</script>